import PropTypes from "prop-types";
import { 
  TextBodyXLarge, 
  TextDisplayLarge, 
  TextLabelLarge 
} from "../common/typography";

const SectionTitle = ({scrollToTarget }) => {

  return(
    <div className="w-full bg-primary30 bg-[url('/assets/gradient-14.svg')] bg-center bg-no-repeat bg-cover px-[30px] py-[40px] md:py-[60px] md:pb-[100px] flex flex-col items-center justify-center text-center">
      
        <h1 className="text-lightOnPrimary md:w-[800px] flex flex-row justify-center">
          <TextDisplayLarge color={'lightOnPrimary'} text={'The Next Generation of Technology Powered Assistants for Real Estate Professionals'} />
        </h1>
        <img
          className="self-stretch h-[8px] relative rounded-[4px] max-w-full overflow-hidden shrink-0"
          loading="lazy"
          alt="Line"
          src="/assets/line-3.svg"
        />

        <div className="mt-5 text-lightPrimaryContainer md:w-[800px] flex flex-row justify-center">
          <TextBodyXLarge color={'lightPrimaryContainer'} text="Growth Partners for Top Producing Real Estate Agents, Brokers & Transaction Coordinators" />
        </div>

        <div className="flex flex-row mt-10 gap-x-4">
            <button className="bg-lightPrimaryContainer w-[182px] h-[48px] rounded-md" onClick={scrollToTarget} >
                <TextLabelLarge color='lightOnPrimaryContainer' text={'Get Started for Free'} />
            </button>
        </div>
    </div>
  );
  
};

SectionTitle.propTypes = {
  className: PropTypes.string,
};

export default SectionTitle;
