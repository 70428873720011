import { 
    TextBodyMedium, 
    TextLabelLarge, 
    TextTitleLerge 
} from "../common/typography"

const  Footer = () => {
    return(
        <div className="bg-primary30 w-full px-4 py-8 lg:px-[108px] lg:py-[40px] flex flex-col lg:flex-row justify-between items-start gap-y-8">
            <div className="w-full lg:w-[392px]">
                <div className="flex flex-row items-center gap-x-2">
                    <img
                        className="h-[35px] w-[35px] relative overflow-hidden shrink-0 object-cover min-h-[35px]"
                        loading="lazy"
                        alt="Rebillion Logo"
                        src="/assets/rebillionLogo.png"
                    />        
                    <TextTitleLerge color={'lightOnPrimary'} text={'ReBillion'} />
                </div>
                <div className="mt-3">
                    <TextBodyMedium color={'lightPrimaryContainer'} text={'ReBillion.ai redefines transaction coordination with AI technology and expert oversight. Complaint, hassle-free, on-time & cost-effective transactions guaranteed.'} />
                </div>
            </div>
            
            <div>
                <TextLabelLarge color={'lightOnPrimary'} text={'Useful links'} />
                <div className="mt-3 flex flex-col gap-y-2 items-start">
                    {/* <div><TextBodyMedium color={'lightPrimaryContainer'} text={'Home'}/></div> */}
                    <button onClick={()=>window.open('https://tc.rebillion.ai/terms-of-service/')}><TextBodyMedium color={'lightPrimaryContainer'} text={'Terms of service'}/></button>
                    <button onClick={()=>window.open('https://tc.rebillion.ai/privacy-policy/')}><TextBodyMedium color={'lightPrimaryContainer'} text={'Privacy policy'}/></button>
                    <button onClick={()=>window.open('https://tc.rebillion.ai/contact/')}><TextBodyMedium color={'lightPrimaryContainer'} text={'Contact'}/></button>
                    {/* <div><TextBodyMedium color={'lightPrimaryContainer'} text={'Services'}/></div> */}
                    <button onClick={()=>window.open('https://tc.rebillion.ai/subscribe-mailer-ai-tools-for-real-estate-agents-transaction-coordinators-realtors-amp-real-estate-brokers/')}><TextBodyMedium color={'lightPrimaryContainer'} text={'Mailing list'}/></button>
                    <button onClick={()=>window.open('https://tc.rebillion.ai/blog/')}><TextBodyMedium color={'lightPrimaryContainer'} text={'Blog'}/></button>
                </div>
            </div>

            <div>
                <TextLabelLarge color={'lightOnPrimary'} text={'Contact Us'} />
                <div className="mt-3">
                    <div><TextBodyMedium color={'lightPrimaryContainer'} text={'Back Office: CoSpaze Coworking, BVR Towers,'}/></div>
                    <div><TextBodyMedium color={'lightPrimaryContainer'} text={'Veerannapalya. Bengaluru 560045. India'}/></div>
                    <div><TextBodyMedium color={'lightPrimaryContainer'} text={'Tc@rebillion.ai'}/></div>
                    <div><TextBodyMedium color={'lightPrimaryContainer'} text={'+16502007853'}/></div>
                </div>
            </div>
        </div>
    )
}

export default Footer