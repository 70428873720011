import { useState } from "react";
import { 
    TextLabelLarge, 
    TextTitleLerge 
} from "../common/typography";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className="flex flex-row justify-between items-center px-4 lg:px-[72px] py-5 lg:py-[22.5px] bg-primary30 relative">
            {/* Logo Section */}
            <div className="flex flex-row items-center gap-x-2">
                <img
                    className="h-[35px] w-[35px] relative overflow-hidden shrink-0 object-cover min-h-[35px]"
                    loading="lazy"
                    alt="Rebillion Logo"
                    src="/assets/rebillionLogo.png"
                />
                <TextTitleLerge color={'lightOnPrimary'} text={'ReBillion'} />
            </div>

            {/* Desktop Menu */}
            <div className="hidden lg:flex flex-row gap-x-5">
                <button onClick={()=>window.open('https://myoffice.rebillion.ai/')}><TextLabelLarge color={'lightPrimaryContainer'} text={'AI Tools'} /></button>
                <button onClick={()=>window.open('https://tc.rebillion.ai/blog/')}><TextLabelLarge color={'lightPrimaryContainer'} text={'Blog'} /></button>
                <button onClick={()=>window.open('https://tc.rebillion.ai/contact/')}><TextLabelLarge color={'lightPrimaryContainer'} text={'Contact'} /></button>
            </div>

            <div className="hidden lg:flex flex-row gap-x-5 w-[200px]"></div>

            {/* Hamburger Menu Icon for Mobile */}
            <div
                className="flex lg:hidden cursor-pointer"
                onClick={() => setMenuOpen(!menuOpen)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-lightPrimaryContainer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                    />
                </svg>
            </div>

            {/* Mobile Menu */}
            {menuOpen && (
                <div className="absolute top-full left-0 w-full bg-primary30 flex flex-col items-center py-4 space-y-4 lg:hidden">
                    <button onClick={()=>window.open('https://myoffice.rebillion.ai/')}><TextLabelLarge color={'lightPrimaryContainer'} text={'AI Tools'} /></button>
                    <button onClick={()=>window.open('https://tc.rebillion.ai/blog/')}><TextLabelLarge color={'lightPrimaryContainer'} text={'Blog'} /></button>
                    <button onClick={()=>window.open('https://tc.rebillion.ai/contact/')}><TextLabelLarge color={'lightPrimaryContainer'} text={'Contact'} /></button>
                </div>
            )}
        </div>
    );
};

export default Header;
