import { 
    TextDisplayMedium, 
    TextTitleLerge 
} from "../common/typography";

const WhyChooseUs = () => {
    
    const whyChooseUsReason = [
        {
            imagePath: './assets/chooseUs1.png',
            reason: 'AI-Powered Efficiency with Human Expertise'
        },
        {
            imagePath: './assets/chooseUs2.png',
            reason: 'Customizable Solutions for Your Business'
        },
        {
            imagePath: './assets/chooseUs3.png',
            reason: 'Time & Cost Savings'
        }
    ]

    return (
        <div className="pb-20 lg:pb-20 w-full flex flex-col justify-center items-center">
            <div className="mb-[60px]">
                <TextDisplayMedium text={'Why Choose us?'} color={'lightOnSurface'} />
            </div>
            <div className="flex flex-col gap-y-10 lg:flex-row justify-center items-center gap-x-10 xl:gap-x-32">
                {
                    whyChooseUsReason.map(reason=>(
                        <div key={reason.reason} className="flex flex-col justify-center text-center items-center w-[303px]">
                            <img
                                className="w-[216px] h-[152px] mb-2"
                                loading="lazy"
                                alt={reason.reason}
                                src={reason.imagePath}
                            />
                            <TextTitleLerge color={'lighOnSurfaceVariant'} text={reason.reason} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};


export default WhyChooseUs;
