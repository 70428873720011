import { 
    TextBodyLarge, 
    TextDisplayMedium, 
    TextHeadlineMedium 
} from "../common/typography"

const FitForEveryone = () => {

    let array = [
        {
            title: 'Brokers',
            description: 'By standardizing the language and terms through the clause library, brokers ensure consistency and efficiency across the team, all while enjoying the benefits of quick document turnaround times',
            line: '/assets/line3-1.svg'
        },
        {
            title: 'Agents',
            description: 'For agents, ReBillion transforms the contract creation process into a swift and seamless task. Agents can customize offers, agreements, and other critical documents with ease.',
            line: '/assets/line3-2.svg'
        },
        {
            title: 'Transaction Coordinator',
            description: 'ReBillion helps Transaction Coordinators facilitate smoother transactions and contributes to overall team success, by ensuring every piece of information is accurately captured and properly formatted.',
            line: '/assets/line3-3.svg'
        }
    ]


    return (
        <div className='py-[60px] px-4 lg:px-[108px] lg:py-20'>
            <div className="text-center">
                <TextDisplayMedium color={'lightOnSurface'}  text={'A perfect fit for everyone'}/>
            </div>
            <div className="mt-[60px] w-full flex flex-col lg:flex-row items-center justify-between gap-y-[64px]">
                {
                    array.map(element=>(
                        <div key={element.title} className="w-full lg:w-[386.67px]"               >
                            <div>
                                <TextHeadlineMedium color={'lightOnSurface'} text={element.title}/>
                                <img
                                    className="self-stretch h-[8px] relative rounded-[4px] max-w-full overflow-hidden shrink-0"
                                    alt={element.title}
                                    src={element.line}
                                    loading="lazy"
                                />
                            </div>
                            <div className="mt-3">
                                <TextBodyLarge color={'lighOnSurfaceVariant'} text={element.description} />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default FitForEveryone